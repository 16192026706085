<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12 mt-0 pt-0">
              <div class="mt-0 pt-0 primary--text">
                <h1 class="text-center today-regular">DATOS DE CONTACTO</h1>
              </div>
            </v-col>
            <v-col cols="10" md="8" lg="7">

              <div class=" pb-6">
                <v-form
                    ref="form"
                    lazy-validation
                    @submit.prevent="sendContactData">

                  <v-text-field
                      type="text"
                      v-model="full_name"
                      :rules="[rules.required]"
                      label="Nombre y apellido *"
                      required
                      outlined
                      dense
                      color="primary"
                      class="input-field"></v-text-field>

                  <v-text-field
                      type="email"
                      v-model="email"
                      :rules="[rules.required]"
                      label="Correo electrónico *"
                      required
                      outlined
                      dense
                      color="primary"
                      class="input-field"></v-text-field>

                  <v-text-field
                      type="number"
                      v-model="cell_phone"
                      :rules="[rules.required, rules.counterMax, rules.counterMin]"
                      label="Celular *"
                      required
                      outlined
                      dense
                      color="primary"
                      class="input-field"></v-text-field>

                  <v-card color="grey lighten-5" elevation="0">

                    <v-list class="transparent">
                      <v-list-item dense>
                        <v-list-item-icon>
                          <v-icon color="primary">mdi-alert-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          <a href="https://vardiseguros.com.co/static/uploads/613b959d-eb39-4cad-b1e4-b9a9344194af.pdf" class="text-caption terms-link font-weight-bold" color="primary" target="_blank">Enviando la información, estás aceptando los términos y condiciones, políticas de privacidad y tratamiento de datos personales</a>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>

                  </v-card>

                  <v-layout justify-center pt-6>
                    <v-card-actions>
                      <v-btn
                          :loading="loading"
                          :disabled="loading"
                          color="primary"
                          class="px-14 text-capitalize vardi-button"
                          type="submit"
                          elevation="0">
                        Enviar
                      </v-btn>
                    </v-card-actions>
                  </v-layout>

                </v-form>
              </div>

            </v-col>
          </v-row>


        </v-container>

      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog"
        max-width="350"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/vardi/enviar.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">SOLICITUD ENVIADA</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-4">Hemos recibido tu información y en las próximas horas un asesor de VARDI se contactará contigo.</h3>

        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              class="px-10 text-capitalize today-bold vardi-button"
              @click="goHome"
              elevation="0"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      Se ha producido un error
    </v-snackbar>

  </div>

</template>

<script>
import NavBarPage from "@/components/Layouts/NavBarPage";
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";

export default {
  name: 'ContactData',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      full_name: '',
      email: '',
      cell_phone: '',
      loading: false,
      dialog: false,
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      snackbar: false,
      timeout: 3000,
      messageError: ''
    }
  },
  methods: {
    goHome() {
      this.dialog = false;
      this.$router.push('/');
    },
    sendContactData() {
      let validate = this.$refs.form.validate();
      if(validate) {
        this.loading = true;
        api.post(constants.endPoints.storeContact, {
          full_name: this.full_name,
          email: this.email,
          cell_phone: this.cell_phone,
          terms: 1
        }, false)
            .then((response) => {
              this.loading = false;
              this.dialog = true;
            }, (error) => {
              console.log('post', error);
              this.loading = false;
              this.snackbar = true;
            });

      }
    }
  }
}
</script>
<style scoped>

.v-toolbar__content {
  justify-content: left !important;
}

.v-list-item--dense {
  min-height: 30px;
}

.v-btn {
  letter-spacing: 0 !important;
  border-radius: 6px;
}

.text-caption {
  letter-spacing: 0 !important;
  line-height: 0.8rem !important;
  font-size: 0.65rem !important;
}

.v-list-item__title {
  white-space: normal !important;
}

</style>